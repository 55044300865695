import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
import { COMMON_PROPS, TEXT_PROPS } from "../../props";

export const FRIENDLY_SPECIFICATIONS_COL: TComponent = {
  componentKey: EComponents.FRIENDLY_SPECIFICATIONS_COL,
  name: EComponents.FRIENDLY_SPECIFICATIONS_COL,
  type: EComponentTypes.COMPONENT,
  displayName: 'Text Column',
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.FRIENDLY_SPECIFICATIONS_ROW],
  props: {
    content: {
      name: "Text",
      defaultValue: "Example text",
    },
    fontSize: {
      ...TEXT_PROPS.fontSize,
      defaultValue: '1',
      items: ['1', '2', '3', '4', '5', '6', '7']
    },
    fontFamily: TEXT_PROPS.fontFamily,
    fontWeight: TEXT_PROPS.fontWeight,
    fontStyle: TEXT_PROPS.fontStyle,
    color: TEXT_PROPS.color,
    direction: TEXT_PROPS.direction,
  }
}

export const FRIENDLY_SPECIFICATIONS_ROW: TComponent = {
  componentKey: EComponents.FRIENDLY_SPECIFICATIONS_ROW,
  name: EComponents.FRIENDLY_SPECIFICATIONS_ROW,
  type: EComponentTypes.COMPONENT,
  displayName: 'Row',
  noHover: true,
  noDynamic: true,
  staticChildren: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.FRIENDLY_SPECIFICATIONS_TABLE]
}

export const FRIENDLY_SPECIFICATIONS_TABLE: TComponent = {
  componentKey: EComponents.FRIENDLY_SPECIFICATIONS_TABLE,
  name: EComponents.FRIENDLY_SPECIFICATIONS_TABLE,
  type: EComponentTypes.COMPONENT,
  displayName: 'Table',
  noHover: true,
  noDynamic: true,
  staticChildren: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.FRIENDLY_SPECIFICATIONS]
}

export const FRIENDLY_SPECIFICATIONS: TComponent = {
  componentKey: EComponents.FRIENDLY_SPECIFICATIONS,
  name: EComponents.FRIENDLY_SPECIFICATIONS,
  type: EComponentTypes.FEATURE,
  displayName: 'Specifications',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  props: {
    tableCount: {
      name: 'Number of tables',
      defaultValue: '1',
      items: ['1', '2']
    },
    backgroundColor: COMMON_PROPS.backgroundColor,
    border: COMMON_PROPS.border,
    borderCollapse: {
      name: "Border Collapse",
      defaultValue: 'collapse',
      items: ['separate', 'collapse']
    }
  },
  children: [
    {
      ...FRIENDLY_SPECIFICATIONS_TABLE,
      children: [{
        ...FRIENDLY_SPECIFICATIONS_ROW,
        children: [
          {
            ...FRIENDLY_SPECIFICATIONS_COL,
            props: {
              ...FRIENDLY_SPECIFICATIONS_COL.props,
              fontWeight: {
                ...FRIENDLY_SPECIFICATIONS_COL.props!.fontWeight,
                defaultValue: '700',
              },
            },
          },
          {
            ...FRIENDLY_SPECIFICATIONS_COL,
          }
        ]
      }]
    },
  ],
};
